<template>
  <div class="flex min-h-screen flex-col items-center justify-center">
    <h1 class="mb-12 text-48 font-bold">
      Not Found
    </h1>
    <p>The site what you try to reach is not found.</p>
  </div>
</template>

<script lang="ts" setup>
import { useHead } from 'unhead';
useHead({
  title: 'Not Found'
})
</script>
